import enums from "enums/index";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { PlanDetailsContext } from "../../PlanDetails.context";
import { formatEnums } from "utils/index";
import { getI18n } from "react-i18next";
import translations from "constants/translations";

//-------------------------------------------------------------------------------------

export default function useMonthlyOrder(resource) {
  const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = React.useState(
    false
  );
  const [monthlyOrders, setMonthlyOrders] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filtersState, setFiltersState] = React.useState({
    fulfillment: null,
  });
  const [isEditingOrder, setIsEditingOrder] = useState();
  const [currentOrder, setCurrentOrder] = useState();
  const [organizations, setOrganizations] = useState();
  const [organizationId, setOrganizationId] = useState();
  const { organization } = React.useContext(PlanDetailsContext);

  const [sendRequest, isLoading] = useHttpClient(true);
  const [sendUpdateRequest, isUpdateLoading] = useHttpClient();

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
    setOrganizationId(updatedFilters?.organization?.id);
  };
  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };
  const dataResource = React.useMemo(() => api.orders, []);
  useEffect(() => {
    (async () => {
      if (window.location.pathname === "/admin/monthly/") {
        try {
          const response = await sendRequest(api.organizations.getAll());
          setOrganizations(response?.data);
        } catch (err) {
          toast.error("An error has occurred");
        }
      } else if (organization?.id) {
        setOrganizationId(organization.id);
      }
    })();
  }, [organization]);

  const getLatestOrders = React.useCallback(async () => {
    try {
      if (!organizationId && resource !== enums.Resource.MONTHLY_FULFILLMENT)
        return;
      const response = await sendRequest(
        dataResource.getOrganizationOrders(
          organizationId,
          enums.TourType.MONTHLY,
          filtersState?.status || undefined,
          filtersState?.serviceProvider?.id || undefined,
          filtersState?.fulfillment || undefined
        )
      );
      const newMonthlyOrders = response.data.map((order, index) => ({
        ...order,
        id: `${order.id}-${index}`,
        realId: order.id,
      }));
      setMonthlyOrders(newMonthlyOrders);
    } catch (err) {
      // toastError("An error has occurred");
    }
  }, [organizationId, filtersState]);

  const updateOrder = useCallback(
    async (orderId, data) => {
      if (orderId && !isNaN(orderId)) {
        if (data.status === enums.OrderStatus.ACTIVE)
          await sendUpdateRequest(
            api.orders.startMonthly(orderId, { id: orderId, ...data })
          );
        else if (data.status === enums.OrderStatus.TERMINATED)
          await sendUpdateRequest(
            api.orders.terminateMonthly(orderId, { id: orderId, ...data })
          );
        else
          await sendUpdateRequest(api.orders.patch({ id: orderId, ...data }));
        await getLatestOrders();
      }
    },
    [getLatestOrders]
  );

  React.useEffect(() => {
    getLatestOrders();
  }, [getLatestOrders]);

  const handleCreateMonthlyOrder = React.useCallback(() => {
    setIsCreateOrderModalOpen(true);
  }, [setIsCreateOrderModalOpen]);
  const handleEditMonthlyOrder = React.useCallback(
    (order) => {
      order = {
        ...order,
        id: order.realId,
        offDays: order.monthly?.offDays?.map((day) => ({
          id: `${day}`,
          name: translations[getI18n().language].weekDay[day],
        })),
        location: order.monthly?.pickupLocation,
        district: order.monthly?.pickupLocation?.district,
        city: order.monthly?.pickupLocation?.district?.city,
        monthlyDistanceQuota: order.monthly?.monthlyDistanceLimit,
        dailyDurationLimit: order.monthly?.dailyDurationLimit,
        truckType: {
          ...order.monthly?.truckType,
          name: order.monthly?.truckType?.details?.name,
        },
      };
      setCurrentOrder(order);
      setIsEditingOrder(true);
      setIsCreateOrderModalOpen(true);
    },
    [setIsCreateOrderModalOpen, setCurrentOrder, setIsEditingOrder]
  );
  const handleCopyMonthlyOrder = React.useCallback(
    (order) => {
      order = {
        deal: order.deal,
        channel: order.channel,
        customerReference: order.customerReference,
        monthly: {
          pickupTime: order.monthly.pickupTime,
        },
        startDate: order.startDate,
        endDate: order.endDate,
        offDays: order.monthly?.offDays?.map((day) => ({
          id: `${day}`,
          name: translations[getI18n().language].weekDay[day],
        })),
        location: order.monthly?.pickupLocation,
        district: order.monthly?.pickupLocation?.district,
        city: order.monthly?.pickupLocation?.district?.city,
        monthlyDistanceQuota: order.monthly?.monthlyDistanceLimit,
        dailyDurationLimit: order.monthly?.dailyDurationLimit,
        truckType: {
          ...order.monthly?.truckType,
          name: order.monthly?.truckType?.details?.name,
        },
      };
      setCurrentOrder(order);
      setIsEditingOrder(false);
      setIsCreateOrderModalOpen(true);
    },
    [setIsCreateOrderModalOpen, setCurrentOrder, setIsEditingOrder]
  );

  const onCreateOrderModalClose = React.useCallback(() => {
    setIsCreateOrderModalOpen(false);
    setIsEditingOrder(false);
    setCurrentOrder(undefined);
  }, [setIsCreateOrderModalOpen, setCurrentOrder, setIsEditingOrder]);

  const statusFilterOptions = useMemo(
    () =>
      [{ name: "All", value: "ALL" }].concat(
        Object.values(enums.OrderStatus).map((filter) => ({
          name: formatEnums(filter),
          value: filter,
        }))
      ),
    []
  );

  return {
    statusFilterOptions,
    organizations,
    filtersState,
    searchQuery,
    isLoading,
    monthlyOrders,
    organizationId,
    isCreateOrderModalOpen,
    isEditingOrder,
    currentOrder,
    setIsEditingOrder,
    setCurrentOrder,
    updateOrder,
    setFiltersState,
    onFilterChange,
    onSearchChange,
    getLatestOrders,
    onCreateOrderModalClose,
    handleCreateMonthlyOrder,
    handleEditMonthlyOrder,
    handleCopyMonthlyOrder,
  };
}
