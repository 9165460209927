import React, { useRef, useEffect } from "react";

// export default PrintDocument = ({ fileUrl, fileName, fileType }) => {

export default function PrintDocument({
  fileUrl,
  fileName,
  onClose,
  fileType,
}) {
  const iframeRef = useRef(null);

  useEffect(() => {
    const printDocument = () => {
      if (iframeRef.current) {
        iframeRef.current.contentWindow.print();
      }
    };

    // Fetch the file and print it immediately after loading
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        // Create an object URL for the blob
        const url = URL.createObjectURL(blob);
        // Set the iframe's src to the blob URL
        iframeRef.current.src = url;
        // Call the print function after a delay to ensure the iframe has loaded
        setTimeout(printDocument, 1000);
      })
      .catch((error) => console.error("Error fetching file:", error));

    // window.addEventListener("afterprint", onClose);
    // window.addEventListener("beforeunload", onClose);

    // Clean up the object URL when the component unmounts
    return () => {
      if (iframeRef?.current?.src) {
        URL.revokeObjectURL(iframeRef.current.src);
      }
      //   window.removeEventListener("afterprint", onClose);
      //   window.removeEventListener("beforeunload", onClose);
    };
  }, [fileUrl]);

  return fileUrl ? (
    <iframe
      ref={iframeRef}
      title={fileName}
      style={{ width: "1px", height: "1px", position: "absolute" }}
    />
  ) : null;
}
