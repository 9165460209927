import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import BackupIcon from "@material-ui/icons/Backup";
import DescriptionIcon from "@material-ui/icons/Description";
import FormField from "components/Forms/FormField/FormField";
import enums from "enums/index";
import { toast } from "react-toastify";
import api from "services/api";
import LocationsForm from "views/Zoning/Locations/LocationsForm";
import DropzoneModal from "../DropzoneModal/DropzoneModal";
import AddButton from "./Components/Buttons/AddButton";
import CancelButton from "./Components/Buttons/CancelButton";
import CompleteButton from "./Components/Buttons/CompleteButton";
import RemoveButton from "./Components/Buttons/RemoveButton";
import StartButton from "./Components/Buttons/StartButton";
import styles from "./TaskRow.styles";
import useTaskRow from "./TaskRow.hook";
import React from "react";
import classNames from "classnames";
import LocationsInput from "views/Common/LocationsInput/LocationsInput";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

export default function TaskRow({ index, data, onAdd, onRemove, formRef }) {
  const {
    onModalClose,
    onModalOpen,
    isModalOpen,
    locations,
    handleAddNewLocation,
    cities,
    districts,
    deal,
    taskStatus,
    taskStatusValue,
    taskDisabled,
    form,
    setCurrentTask,
    updateTaskStatus,
    taskDocuments,
    startButtonShown,
    statusChangeDisabled,
    completeTaskDisabled,
    statusChangeMessage,
    onCompleteTask,
    onStartTask,
    errorMessage,
    handleTaskTypeChange,
    isLocationDisabled,
    getLocationTaskTypes,
    diffEnd,
    diffStart,
    currentTour,
    taskType,
    tourPickupLocationId,
  } = useTaskRow(index, data);
  const { setValue } = form;

  const classes = useStyles();

  const firstColClasses = classNames({
    [classes.rowCell]: true,
    [classes.stickyCol]: true,
    [classes.firstCol]: true,
  });
  const lastColClasses = classNames({
    [classes.rowCell]: true,
    [classes.stickyCol]: true,
    [classes.lastCol]: true,
  });

  const locationsFilters = {
    dealId: deal?.id,
    taskType,
    tourType: currentTour?.type,
    truckTypeId: currentTour?.truckType?.id,
    kmLimit: currentTour?.quotation?.rental?.details?.kmLimit,
    daysHoursLimit: currentTour?.quotation?.rental?.details?.daysHoursLimit,
    daysLimit: currentTour?.quotation?.rental?.details?.daysLimit,
    pickupLocationId: tourPickupLocationId,
  };

  return (
    <tr style={{ width: "100%" }}>
      <td className={firstColClasses}>
        <FormField
          name={`tasks.${index}.type`}
          form={form}
          type="select"
          onChange={handleTaskTypeChange}
          options={{
            enum: "taskType",
            disabled: taskDisabled || index === 0,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>
      <td className={classes.bigRowCell}>
        <LocationsInput
          name={`tasks.${index}.location`}
          form={form}
          type="autocomplete"
          locations={locations}
          districts={districts}
          cities={cities}
          disabled={isLocationDisabled}
          tourId={currentTour?.id}
          taskType={taskType}
          filters={locationsFilters}
          formComponent={
            <LocationsForm
              getLocationTaskTypes={getLocationTaskTypes}
              deal={deal}
              cities={cities}
              districts={districts}
              handleAddNew={handleAddNewLocation}
              create
            />
          }
        />
      </td>

      <td className={classes.rowCell}>
        <FormField
          name={`tasks.${index}.startTime`}
          form={form}
          type="time"
          options={{ isTableInput: true, disabled: taskDisabled }}
        />
        {diffStart ? (
          <div
            style={{
              textAlign: "right",
              color: primaryColor[0],
              fontWeight: "bold",
            }}
          >
            +{diffStart} day{diffStart !== 1 && "s"}
          </div>
        ) : (
          <div style={{ height: 21 }} />
        )}
      </td>

      <td className={classes.rowCell}>
        <FormField
          name={`tasks.${index}.endTime`}
          form={form}
          type="time"
          options={{ isTableInput: true, disabled: taskDisabled }}
        />
        {diffEnd ? (
          <div
            style={{
              textAlign: "right",
              color: primaryColor[0],
              fontWeight: "bold",
            }}
          >
            +{diffEnd} day{diffEnd !== 1 && "s"}
          </div>
        ) : (
          <div style={{ height: 21 }} />
        )}
      </td>

      <td className={classes.rowCell}>
        <FormField
          name={`tasks.${index}.distance`}
          form={form}
          type="number"
          options={{
            disabled: taskDisabled,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>

      <td className={classes.rowCell}>
        <FormField
          name={`tasks.${index}.odometerReading`}
          form={form}
          type="number"
          options={{
            disabled: taskDisabled,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
      </td>

      <td className={classes.rowCell}>
        <span style={{ display: "flex", justifyContent: "center" }}>
          {taskDocuments?.length > 0 ? (
            <Tooltip
              title={`${taskDocuments.length} ${
                taskDocuments.length > 1 ? "Documents" : "Document"
              } Attached`}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
              arrow
            >
              <DescriptionIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!taskDisabled) {
                    setCurrentTask(index);
                    onModalOpen();
                  }
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={"Upload Documents"}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
              arrow
            >
              <BackupIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (!taskDisabled) {
                    setCurrentTask(index);
                    onModalOpen();
                  }
                }}
              />
            </Tooltip>
          )}
        </span>
      </td>

      <td className={classes.rowCell}>
        <span style={{ display: "flex", justifyContent: "center" }}>
          {taskStatusValue}
        </span>
      </td>

      <div hidden>
        <FormField
          name={`tasks.${index}.status`}
          form={form}
          type="select"
          options={{
            enum: "taskStatus",
            disabled: true,
            blurOnSelect: false,
            isTableInput: true,
          }}
        />
        <FormField
          name={`tasks.${index}.documents`}
          form={form}
          type="dropzone"
          options={{
            maxFiles: 5,
          }}
        />
      </div>

      {!taskDisabled && (
        <td
          className={lastColClasses}
          style={{
            borderWidth: `0 0px 1px 0`,
          }}
        >
          <span style={{ display: "flex", justifyContent: "center" }}>
            <AddButton onAdd={() => onAdd(index)} />
            {index !== 0 && (
              <RemoveButton onRemove={() => onRemove(index)} index={index} />
            )}
            {startButtonShown && (
              <StartButton
                statusChangeDisabled={statusChangeDisabled}
                statusChangeMessage={statusChangeMessage}
                onStart={onStartTask}
                errorMessage={errorMessage}
                index={index}
              />
            )}
            {taskStatus === enums.TaskStatus.ONGOING &&
              taskStatus !== enums.TaskStatus.COMPLETED && (
                <CompleteButton
                  statusChangeDisabled={completeTaskDisabled}
                  onComplete={onCompleteTask}
                  errorMessage={errorMessage}
                  index={index}
                />
              )}
            {taskStatus !== enums.TaskStatus.CANCELLED && (
              <CancelButton
                onCancel={() => {
                  updateTaskStatus(enums.TaskStatus.CANCELLED);
                }}
                index={index}
              />
            )}
          </span>
        </td>
      )}
      <DropzoneModal
        isOpen={isModalOpen}
        onClose={onModalClose}
        index={index}
        setDocuments={(value) => {
          setValue(`tasks.${index}.documents`, value);
        }}
        formRef={formRef}
      />
    </tr>
  );
}
