export default {
  BEIGE: "BEIGE",
  BLACK: "BLACK",
  BLUE: "BLUE",
  BROWN: "BROWN",
  GRAY: "GRAY",
  RED: "RED",
  SILVER: "SILVER",
  WHITE: "WHITE",
};
