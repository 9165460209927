import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Button from "components//CustomButtons/Button";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer";
import Loading from "components/Loading/Loading";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import * as yup from "yup";
import styles from "./Updates.styles";

TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo("en-US");

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

const Update = ({ update, updateContainerClasses, senderTextClasses }) => {
  return (
    <div className={updateContainerClasses}>
      <span>
        <span className={senderTextClasses}>{update?.addedBy?.name}:</span>
        <span>({timeAgo.format(new Date(update.createdAt))})</span>
      </span>
      <div>{update.text}</div>
    </div>
  );
};

export default function Updates({ id, referenceKey, dataResource, ...props }) {
  const resolver = useYupValidationResolver(
    yup.object().shape({ update: yup.string().required("Required") })
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });
  const { handleSubmit, reset, getValues } = form;

  const classes = useStyles();

  const [updates, setUpdates] = useState([]);
  const [sendRequest, isLoading] = useHttpClient();
  const [sendGetUpdatesRequest, isUpdatesLoading] = useHttpClient();

  useEffect(() => {
    if (!id) return;
    (async () => {
      const updatesResponse = (
        await sendGetUpdatesRequest(
          dataResource.getAll(undefined, undefined, undefined, {
            [referenceKey]: id,
          })
        )
      ).data;
      setUpdates(updatesResponse);
    })();
  }, [id]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };

  const onSubmit = (values) => async () => {
    try {
      const newUpdate = await sendRequest(
        dataResource.create({
          text: values.update,
          [referenceKey]: parseInt(id),
        })
      );
      handleAddUpdate(newUpdate);
      toast.success(`Update has been created successfully`);
      reset({ update: "" });
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  const handleAddUpdate = (newUpdate) => {
    setUpdates((prevUpdates) => [...prevUpdates, newUpdate]);
  };

  const cardBodyClasses = isUpdatesLoading ? classes.updatesCardLoading : null;

  return (
    <GridContainer>
      <Card className={classes.card}>
        <CardBody className={cardBodyClasses}>
          <div className={classes.cardContainer}>
            <Loading
              loading={isUpdatesLoading}
              circularProgressCustomClasses={classes.loading}
            >
              <div className={classes.cardInnerContainer}>
                {updates
                  .map((update) => (
                    <Update
                      update={update}
                      updateContainerClasses={classes.updateContainer}
                      senderTextClasses={classes.senderText}
                    />
                  ))
                  .reverse()}
              </div>
              <form onSubmit={submitHandler} className={classes.form}>
                <div className={classes.formContainer}>
                  <FormField
                    name="update"
                    type="text"
                    options={{
                      inputCustomClasses: classes.input,
                      variant: "outlined",
                    }}
                    form={form}
                  />
                  <Button
                    color="primary"
                    aria-label="edit"
                    className={classes.button}
                    type="submit"
                    loading={isLoading}
                  >
                    Add Update
                  </Button>
                </div>
              </form>
            </Loading>
          </div>
        </CardBody>
      </Card>
    </GridContainer>
  );
}

Updates.propTypes = {
  id: PropTypes.number,
  referenceKey: PropTypes.string,
  dataResource: PropTypes.func,
};
