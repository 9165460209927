import enums from "enums/index";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import { ServicesTableContext } from "../ServiceTable.context";
export default function useAddServiceModal({ currentTour, onClose }) {
  const form = useForm({
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      serviceProvider: {},
      service: {},
      targetCost: 0,
      cost: 0,
      quantity: 1,
    },
  });

  const [services, setServices] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [serviceSendRequest, isServiceLoading] = useHttpClient(true);
  const { serviceProviders } = useContext(TourOperationsContext);
  const { getServices } = useContext(ServicesTableContext);
  useEffect(() => {
    (async () => {
      try {
        if (currentTour) {
          const response = await serviceSendRequest(
            api.deals.getAdditionalServices(currentTour.order.dealId)
          );
          setServices(response.data);
        }
      } catch {}
    })();
  }, [currentTour]);

  const { handleSubmit, setValue, getValues, watch, reset } = form;

  const service = watch("service");
  const serviceId = service?.id;

  useEffect(() => {
    if (serviceId && !isSubmitted) {
      setValue("targetCost", service.cost);
      setValue("cost", service.cost);
    }
  }, [serviceId]);
  useEffect(() => {
    if (isSubmitted) {
      reset();
    }
  }, [isSubmitted]);

  const onSubmit = (values) => async () => {
    try {
      const service = {
        tourId: currentTour.id,
        serviceProviderId: values.serviceProvider.id,
        type: enums.ServiceType.ADDITIONAL,
        serviceId: values.service.id,
        quantity: values.quantity,
        targetCost: values.targetCost,
        targetPrice: values.service.price,
        cost: values.cost,
        price: values.service.price,
        totalCost: values.quantity * values.cost,
        totalPrice: values.quantity * values.service.price,
      };
      await serviceSendRequest(
        api.tours.updateTourServices(currentTour.id, {
          services: [...currentTour.services, service],
        })
      );
      setIsSubmitted(true);
      onClose();
      getServices();
    } catch (err) {}
  };

  const submitHandler = (e) => {
    e.preventDefault();
    let values = getValues();
    handleSubmit(onSubmit(values), onError)();
  };
  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    serviceProviders,
    form,
    reset,
    isServiceLoading,
    submitHandler,
    services,
  };
}
