import axios from "axios";
import { logInWithEmailAndPassword } from "./firebase";
import qs from "qs";
import { auth } from "./firebase";
import { attachTimeZoneToDate } from "utils/index";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  withCredentials: true,
});

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
axiosInstance.defaults.headers.common["X-Timezone"] = timezone;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
      return;
    }
    prom.resolve(token);
  });
  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    /* Reject promise if usual error */
    if (!error.response || error.response?.status !== 401) {
      return Promise.reject(error);
    }
    let originalRequest = error.response.config;

    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((accessToken) => {
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    isRefreshing = true;
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    // axiosInstance.interceptors.response.eject(interceptor);
    return new Promise(async function (resolve) {
      try {
        const newAccessToken = await auth.currentUser.getIdToken(true);
        setAuthToken(newAccessToken);
        error.response.config.headers.authorization = `Bearer ${newAccessToken}`;
        isRefreshing = false;
        processQueue(null, newAccessToken);
        resolve(axiosInstance(error.response.config));
      } catch (err) {
        isRefreshing = false;
        processQueue(error, null);
        resolve({ response: { status: 401 } });
      }
      // finally {
      //   return createAxiosResponseInterceptor;
      // }
    });
  }
);

// const createAxiosResponseInterceptor = async () => {
//   const interceptor = axiosInstance.interceptors.response.use(
//     async (response) => response,
//     async (error) => {
//       /* Reject promise if usual error */
//       if (!error.response || error.response?.status !== 401) {
//         return Promise.reject(error);
//       }
//       let originalRequest = error.response.config;

//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           failedQueue.push({ resolve, reject });
//         })
//           .then((accessToken) => {
//             originalRequest.headers.authorization = `Bearer ${accessToken}`;
//             return axiosInstance(originalRequest);
//           })
//           .catch((err) => {
//             return Promise.reject(err);
//           });
//       }
//       isRefreshing = true;
//       /*
//        * When response code is 401, try to refresh the token.
//        * Eject the interceptor so it doesn't loop in case
//        * token refresh causes the 401 response
//        */
//       axiosInstance.interceptors.response.eject(interceptor);
//       return new Promise(async function (resolve) {
//         try {
//           const newAccessToken = await auth.currentUser.getIdToken(true);
//           setAuthToken(newAccessToken);
//           error.response.config.headers.authorization = `Bearer ${newAccessToken}`;
//           isRefreshing = false;
//           processQueue(null, newAccessToken);
//           resolve(axiosInstance(error.response.config));
//         } catch (err) {
//           isRefreshing = false;
//           processQueue(error, null);
//           resolve({ response: { status: 401 } });
//         } finally {
//           return createAxiosResponseInterceptor;
//         }
//       });
//     }
//   );
// };

// createAxiosResponseInterceptor();

const setAuthToken = async (token) => {
  axiosInstance.defaults.headers.common.authorization = `Bearer ${token}`;
};

const clearAuthToken = async () => {
  return setAuthToken("");
};

const login = (authData) => {
  return logInWithEmailAndPassword(authData.id, authData.password);
  // return axiosInstance.post("auth/user/login", authData);
};

const refreshToken = () => axiosInstance.get("/auth/user/token/refresh");

const logout = () => axiosInstance.get("auth/user/logout");

const createFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key]?.length) {
      data[key].forEach((el, index) => {
        formData.append(`${key}[${index}]`, JSON.stringify(el));
      });
    } else {
      formData.append(
        key,
        typeof data[key] === "object" && data[key]?.arrayBuffer === undefined
          ? JSON.stringify(data[key])
          : data[key]
      );
    }
  });
  return formData;
};

const createDataResource = (path, isMultipart = false) => ({
  getAll: (
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined,
    orderBy = undefined
  ) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`${path}?${query}`);
  },
  getOne: (id) => axiosInstance.get(`${path}/${id}`),
  create: (data) => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.post(path, formData, config);
    } else {
      response = axiosInstance.post(path, data);
    }
    return response;
  },
  createMany: (data) => axiosInstance.post(`${path}/bulk`, data),
  patch: (data) => {
    let response;
    const id = data.id;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.patch(`${path}/${id}`, formData, config);
    } else {
      response = axiosInstance.patch(`${path}/${id}`, data);
    }
    return response;
  },
  delete: (id) => axiosInstance.delete(`${path}/${id}`),
  updateMany: (data) => axiosInstance.patch(path, data),
});

const deals = {
  ...createDataResource("deals"),
  getTruckTypes: (dealId) => axiosInstance.get(`deals/${dealId}/truck-types`),
  updateTruckType: (dealId, truckTypeId, data) =>
    axiosInstance.patch(`deals/${dealId}/truck-types/${truckTypeId}`, data),
  getQuotationGroups: (dealId) =>
    axiosInstance.get(`deals/${dealId}/quotation-groups`),
  submitForPricing: (dealId) =>
    axiosInstance.post(`deals/${dealId}/submit-for-pricing`),
  finalizePricing: (dealId) =>
    axiosInstance.post(`deals/${dealId}/finalize-pricing`),
  lose: (dealId, dealLossReasons) =>
    axiosInstance.post(`deals/${dealId}/lose`, { dealLossReasons }),
  activate: (dealId) => axiosInstance.post(`deals/${dealId}/activate`),
  accept: (dealId) => axiosInstance.post(`deals/${dealId}/accept`),
  reject: (dealId) => axiosInstance.post(`deals/${dealId}/reject`),
  resubmitPricing: (dealId) =>
    axiosInstance.post(`deals/${dealId}/resubmit-pricing`),
  getAdditionalServices: (dealId, data) =>
    axiosInstance.get(`deals/${dealId}/services`, data),
  addService: (dealId, data) =>
    axiosInstance.post(`deals/${dealId}/services`, data),
  updateService: (dealId, serviceId, data) =>
    axiosInstance.patch(`deals/${dealId}/services/${serviceId}`, data),
  deleteService: (dealId, serviceId) =>
    axiosInstance.delete(`deals/${dealId}/services/${serviceId}`),
  getCities: (dealId, filters = undefined) => {
    let query = { ...filters };
    query = qs.stringify(query);
    return axiosInstance.get(`deals/${dealId}/cities?${query}`);
  },
  getDistricts: (dealId, filters = undefined) => {
    let query = { ...filters };
    query = qs.stringify(query);
    return axiosInstance.get(`deals/${dealId}/districts?${query}`);
  },
  getLocations: (dealId, filters = undefined) => {
    let query = { ...filters };
    query = qs.stringify(query);
    return axiosInstance.get(`deals/${dealId}/locations?${query}`);
  },
  getQuotationTypes: (dealId, tourType) => {
    let query = qs.stringify({ tourType });
    return axiosInstance.get(`deals/${dealId}/quotation-types?${query}`);
  },
  assignOrganization: (dealId, organizationId) =>
    axiosInstance.patch(`deals/${dealId}/organization/${organizationId}`),
  getDealQuotations: (
    dealId,
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined,
    orderBy = undefined
  ) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`deals/${dealId}/quotations?${query}`);
  },
  exportDealQuotations: (
    dealId,
    q = undefined,
    filters = undefined,
    orderBy = undefined
  ) => {
    let query = { q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`deals/${dealId}/quotations/export?${query}`, {
      responseType: "blob",
    });
  },
  importDealQuotations: (dealId, fileUrl) => {
    return axiosInstance.patch(`deals/${dealId}/quotations/import`, {
      fileUrl,
    });
  },
};

const uploadFile = (file) => {
  const formData = createFormData({ file });
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axiosInstance.post(`file-upload`, formData, config);
};

const uploadFiles = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append("files", data[key]);
  });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axiosInstance.post(`file-upload/bulk`, formData, config);
};

const users = {
  ...createDataResource("users"),
  fetchUserData: (firebaseID) =>
    axiosInstance.get(`users/firebase/${firebaseID}`),
};
const permissions = createDataResource("permissions");
const departments = createDataResource("departments");
const roles = createDataResource("roles");
const resources = createDataResource("resources");
const services = createDataResource("services");
const cities = createDataResource("cities");
const countries = createDataResource("countries");
const districts = createDataResource("districts");
const locationTypes = createDataResource("location-types");
const zones = createDataResource("zones");
const tags = createDataResource("tags");
const locations = {
  ...createDataResource("locations"),
  getTaskLocations: (
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = {}
  ) => {
    let query = { page, limit, q, ...filters };
    query = qs.stringify(query);
    return axiosInstance.get(`/locations/task?${query}`);
  },
};

const zoneMappings = {
  ...createDataResource("zone-mappings"),
  getZoneMappingByOriginAndDestination: (originId, destinationId) =>
    axiosInstance.get(
      `zone-mappings/origin/${originId}/destination/${destinationId}`
    ),
};
const organizations = {
  ...createDataResource("organizations"),
  getQuotationTypes: (organizationId) =>
    axiosInstance.get(`organizations/${organizationId}/quotation-types`),

  getDispatchingDetails: (
    orgId,
    resource,
    { page, limit, q, filters, orderBy } = {}
  ) => {
    let clientTime = new Date();
    clientTime.setHours(0, 0, 0, 0);
    clientTime = attachTimeZoneToDate(clientTime);
    let query = { page, limit, q, clientTime, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(
      `organizations/dispatching-details/${orgId}/${resource}?${query}`
    );
  },
  getDispatchingOrganizations: (
    resource,
    { page, limit, q, filters, orderBy } = {}
  ) => {
    let clientTime = new Date();
    clientTime.setHours(0, 0, 0, 0);
    clientTime = attachTimeZoneToDate(clientTime);
    let query = { page, limit, q, ...filters, clientTime, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(
      `organizations/dispatching-details/${resource}?${query}`
    );
  },
  getAvailableLocations: (organizationId) =>
    axiosInstance.get(`organizations/${organizationId}/available-locations`),
  getOrganizationsWithInvoicesCount: (status, q) => {
    const query = qs.stringify({ q, status });
    return axiosInstance.get(`organizations/invoices-count?${query}`);
  },
};
const contacts = createDataResource("contacts");
const contracts = createDataResource("contracts");
const truckTypes = createDataResource("truck-types");
const categories = createDataResource("categories");
const industries = createDataResource("industries");
const globals = createDataResource("globals");
const trucks = createDataResource("trucks");
const serviceProviders = {
  ...createDataResource("service-providers"),
  getServiceProvidersWithBillsCount: (status, q) => {
    const query = qs.stringify({ q, status });
    return axiosInstance.get(`service-providers/bills-count?${query}`);
  },
};
const couriers = createDataResource("couriers");
const drivers = createDataResource("drivers");
const plans = createDataResource("plans");
const invoices = {
  ...createDataResource("invoices"),
  getInvoiceTours: (id, { page, limit, q, filters, orderBy } = {}) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`invoices/${id}/tours?${query}`);
  },
  getRevisions: (id) => axiosInstance.get(`invoices/${id}/revisions`),
};
const bills = {
  ...createDataResource("bills"),
  getBillTours: (id, { page, limit, q, filters, orderBy } = {}) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`bills/${id}/tours?${query}`);
  },
  getRevisions: (id) => axiosInstance.get(`bills/${id}/revisions`),
};
const invoiceUpdates = createDataResource("invoice-updates");
const billUpdates = createDataResource("bill-updates");
const tourUpdates = createDataResource("tour-updates");
const penalties = createDataResource("penalties");
const discounts = createDataResource("discounts");
const orders = {
  ...createDataResource("orders"),
  assignPlan: (data) => axiosInstance.patch("orders/plan", data),
  assignTourWorker: (data) => axiosInstance.post("orders/tour-worker", data),

  getPlan: (organizationId, date) => {
    let query = { organizationId, date };
    query = qs.stringify(query);
    return axiosInstance.get(`orders/plan?${query}`);
  },
  getOrganizationOrders: (
    organizationId,
    type,
    status = undefined,
    serviceProviderId = undefined,
    fulfillment = undefined
  ) => {
    let query = {
      organizationId,
      type,
      status,
      serviceProviderId,
      fulfillment,
    };
    query = qs.stringify(query);
    return axiosInstance.get(`orders?${query}`);
  },
  addTask: (task) => axiosInstance.post(`orders/task`, task),
  startMonthly: (id, data) =>
    axiosInstance.patch(`orders/${id}/monthly/start`, data),
  terminateMonthly: (id, data) =>
    axiosInstance.patch(`orders/${id}/monthly/terminate`, data),
};

const tours = {
  ...createDataResource("tours"),
  updateTourTasks: (tourId, data) => {
    return axiosInstance.patch(`/tours/${tourId}`, data);
  },
  removeServiceProvider: (tourId, data) =>
    axiosInstance.patch(`/tours/${tourId}/remove-service-provider`, data),
  removeCourier: (tourId) =>
    axiosInstance.patch(`/tours/${tourId}/remove-courier`),
  cancelTour: (tourId, data) =>
    axiosInstance.patch(`/tours/${tourId}/cancel`, data),
  updateTourServices: (tourId, data) => {
    return axiosInstance.patch(`/tours/${tourId}/services`, data);
  },
  getTourServices: (tourId) => {
    return axiosInstance.get(`/tours/${tourId}/services`);
  },
  getTourWithPenaltiesAndDiscounts: (tourId) => {
    return axiosInstance.get(`/tours/${tourId}/fin-ops-data`);
  },
  updateTourService: (tourId, data) => {
    return axiosInstance.patch(`/tours/${tourId}/tour-service`, data);
  },
  updateTourTask: (tourId, taskId, data) => {
    return axiosInstance.patch(`/tours/${tourId}/tasks/${taskId}`, data);
  },
  getTourQuotationById: (tourId) => {
    return axiosInstance.get(`/tours/${tourId}/quotation`);
  },
  getRevisions: (id) => axiosInstance.get(`tours/${id}/revisions`),
};

const quotations = {
  ...createDataResource("quotations"),
  getAvailableTrucks: (quotationId, filters = {}) => {
    const query = qs.stringify(filters);
    return axiosInstance.get(`/quotations/${quotationId}/trucks?${query}`);
  },
};
const serviceProviderRentals = {
  ...createDataResource("service-provider-rental"),
  getAllForDispatching: (
    pickupDate,
    { page, limit, q, filters, orderBy } = {}
  ) => {
    let query = { page, limit, q, ...filters, pickupDate, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`service-provider-rental/dispatching?${query}`);
  },
  getTours: (
    id,
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined,
    orderBy = undefined
  ) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`service-provider-rental/${id}/tours?${query}`);
  },
};
const courierRentals = {
  ...createDataResource("courier-rental"),
  getAllForDispatching: (
    pickupDate,
    { page, limit, q, filters, orderBy } = {}
  ) => {
    let query = { page, limit, q, ...filters, pickupDate, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`courier-rental/dispatching?${query}`);
  },
};
export default {
  users,
  permissions,
  departments,
  roles,
  resources,
  services,
  cities,
  countries,
  districts,
  locations,
  zones,
  zoneMappings,
  locationTypes,
  tags,
  deals,
  organizations,
  contacts,
  contracts,
  truckTypes,
  categories,
  industries,
  quotations,
  discounts,
  penalties,
  invoices,
  bills,
  invoiceUpdates,
  billUpdates,
  tourUpdates,
  globals,
  plans,
  orders,
  trucks,
  serviceProviders,
  couriers,
  drivers,
  tours,
  serviceProviderRentals,
  courierRentals,
  uploadFile,
  uploadFiles,
  login,
  refreshToken,
  setAuthToken,
  clearAuthToken,
  logout,
};
