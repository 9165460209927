import React from "react";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import { Link } from "react-router-dom";
import FinOpsList from "views/FinOps/FinOpsList/FinOpsList";
import enums from "enums/index";
import api from "services/api";
import {
  extractTimeFromISO,
  formatEnums,
  getDateString,
  isEmpty,
} from "utils/index";
import FinOpsEdit from "views/FinOps/FinOpsEdit/FinOpsEdit";
import * as yup from "yup";
import moment from "moment";

// appear in breadcrumbs and page headers
const listPageName = "Bills";
const editPageName = "Edit Bill";

// appears on alerts; example: Entity created successfully
const entityName = "Bill";

const resource = enums.Resource.BILLS;

const layout = "/admin";

const path = "bills";

const dataResource = api.bills;

const getColumns = (handleEditModalOpen) => [
  {
    title: "Bill ID",
    dataIndex: "referenceNumber",
  },
  {
    title: "Bill Name",
    dataIndex: "name",
  },
  {
    title: "No. of Tours",
    dataIndex: "numberOfTours",
  },
  {
    title: "Total Cost",
    dataIndex: "totalCost",
  },
  {
    title: "Total Penalty",
    dataIndex: "totalPenalty",
  },
  {
    title: "Net Cost",
    dataIndex: "netCost",
  },
  {
    title: "Expected Bill Date",
    dataIndex: "expectedBillDate",
    getData: (value) => (value ? getDateString(value) : "N/A"),
  },
  {
    title: "",
    render: (_, record) => {
      return (
        <div style={{ display: "flex", width: "fit-content" }}>
          <CheckPermission resource={resource} action={enums.Action.READ}>
            <Link to={`/admin/bills/${record?.id}/edit`}>
              <Button justIcon next color="white" textColor="primary" />
            </Link>
          </CheckPermission>{" "}
          <CheckPermission resource={resource} action={enums.Action.UPDATE}>
            <Button
              justIcon
              edit
              color="white"
              textColor="success"
              onClick={() => {
                handleEditModalOpen(record?.id);
              }}
            />
          </CheckPermission>
        </div>
      );
    },
  },
];
const editFormStructure = [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Bill Name",
        validation: yup.mixed().required("Bill name is required"),
      },
      {
        name: "actualBillDate",
        type: "date",
        label: "Actual Bill Date",
        options: {
          blurOnSelect: false,
        },
      },
      {
        name: "expectedBillDate",
        type: "date",
        label: "Expected Bill Date",
        options: {
          blurOnSelect: false,
        },
      },
      {
        name: "status",
        type: "select",
        label: "Bill Status",
        options: {
          enum: "billStatus",
        },
        validation: yup.mixed().required("Bill status is required"),
      },
    ],
  },
];

const getTotalDuration = (tasks) => {
  const validTasks = tasks?.filter((task) => task.startTime && task.endTime);

  let totalDuration = moment.duration();

  const i = validTasks?.forEach((task) => {
    const startTime = moment(task.startTime);
    const endTime = moment(task.endTime);

    const duration = moment.duration(endTime.diff(startTime));
    totalDuration.add(duration);
  });
  const days = totalDuration.days();
  const hours = totalDuration.hours();
  const minutes = totalDuration.minutes();

  return days || hours || minutes
    ? `${totalDuration.days()} d, ${totalDuration.hours()} h, ${totalDuration.minutes()} m`
    : "-";
};

const getFirstStartTime = (tasks) => {
  const firstStartTime = tasks[0]?.startTime
    ? new Date(tasks[0].startTime).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    : null;
  return firstStartTime || "-";
};

const getLastEndTime = (tasks) => {
  const validTasks = tasks?.filter((task) => task.endTime);

  const lastEndTime = validTasks?.length
    ? new Date(validTasks?.[validTasks.length - 1].endTime).toLocaleTimeString(
        "en-US",
        {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      )
    : null;
  return lastEndTime || "-";
};

const editColumns = [
  {
    title: "Tour ID",
    render: (_, record) => {
      return (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link
            to={`/admin/finops?organizationId=${record?.organizationId}&tourId=${record?.id}`}
            target="_blank"
          >
            <a style={{ color: "blue" }}>{record?.id}</a>
          </Link>
        </div>
      );
    },
  },
  {
    title: "Pickup Date",
    dataIndex: "pickupTime",
    getData: (value) => (value ? getDateString(value) : "N/A"),
  },
  {
    title: "Trip Type",
    dataIndex: "type",
    getData: (value) => (value ? formatEnums(value) : "N/A"),
  },
  {
    title: "Truck Type",
    dataIndex: ["truckType", "details", "name"],
  },
  {
    title: "Pickup Location",
    dataIndex: ["pickupLocation", "name"],
    canBeHighlighted: true,
  },
  {
    title: "Drop Off Location",
    dataIndex: ["dropOffLocation", "name"],
    canBeHighlighted: true,
  },
  {
    title: "Service Provider",
    dataIndex: ["serviceProvider", "name"],
  },
  {
    title: "Driver",
    dataIndex: ["driver", "name"],
    canBeHighlighted: true,
  },
  {
    title: "Truck",
    dataIndex: ["truck", "name"],
    canBeHighlighted: true,
  },

  {
    title: "Services Price",
    dataIndex: "servicesPrice",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  {
    title: "Penalties Price",
    dataIndex: "penaltiesPrice",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  {
    title: "Net Price",
    dataIndex: "netPrice",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  {
    title: "Total Duration",
    dataIndex: "tasks",
    getData: (value) => {
      return getTotalDuration(value);
    },
  },
  {
    title: "First Task Start Time",
    dataIndex: "tasks",
    getData: (value) => {
      return getFirstStartTime(value);
    },
  },
  {
    title: "Last Task End Time",
    dataIndex: "tasks",
    getData: (value) => {
      return getLastEndTime(value);
    },
  },
];

const infoColumns = [
  {
    title: "Expected Bill Date",
    dataIndex: "expectedBillDate",
    getData: (value) => (value ? getDateString(value) : "N/A"),
    editable: true,
    type: "date",
  },
  {
    title: "Service Provider",
    dataIndex: "serviceProvider",
    getData: (value) => value?.name,
  },
  { title: "Bill ID", dataIndex: "referenceNumber" },
  { title: "No.of Tours", dataIndex: "numberOfTours" },
  {
    title: "Status",
    dataIndex: "status",
    getData: (value) => (value ? formatEnums(value) : "N/A"),
  },
  {
    title: "Total Amount",
    dataIndex: "total",
    getData: (value) => (!isEmpty(value) ? value.toFixed(2) : "N/A"),
  },
  { title: "Penalty", dataIndex: "deductions" },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "receipt",
  render: () => (
    <FinOpsList
      resource={resource}
      filters={Object.values(enums.BillStatus).map((filter) => ({
        name: formatEnums(filter),
        value: filter,
      }))}
      apiRequest={api.serviceProviders.getServiceProvidersWithBillsCount}
      selectorKey="serviceProviderId"
      getColumns={getColumns}
      entityName={entityName}
      dataResource={dataResource}
      editFormStructure={editFormStructure}
      path={path}
    />
  ),
  action: "read",
  resource,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FinOpsEdit
          resource={resource}
          request={api.bills.getBillTours}
          updatesDataResource={api.billUpdates}
          referenceKey="billId"
          columns={editColumns}
          infoColumns={infoColumns}
        />
      ),
      layout: layout,
      action: "read",
      resource,
    },
  ],
};
