import { makeStyles } from "@material-ui/core/styles";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import InfoCard from "views/FinOps/FinOpsEdit/components/InfoCard/InfoCard";
import Updates from "views/FinOps/FinOpsEdit/components/Updates/Updates";
import History from "views/FinOps/FinOpsEdit/components/History/History";
import StatusButton from "views/FinOps/FinOpsEdit/components/StatusButton/StatusButton";
import useFinOpsEdit from "./FinOpsEdit.hook";
import styles from "./FinOpsEdit.styles";
import enums from "enums/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function FinOpsEdit(props) {
  const classes = useStyles();

  const {
    resource,
    request,
    updatesDataResource,
    referenceKey,
    columns,
    infoColumns,
  } = props;

  const {
    id,
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    info,
    infoLoading,
    updateInfo,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
  } = useFinOpsEdit({
    resource,
    request,
    filterInputs,
  });

  const filterInputs = React.useMemo(() => {
    return undefined;
  }, []);

  return (
    <div>
      <div className={classes.topInnerContainer}>
        <div className={classes.infoContainer}>
          <InfoCard
            info={info}
            updateInfo={updateInfo}
            loading={infoLoading}
            columns={infoColumns}
          />
        </div>
        <div className={classes.updatesContainer}>
          <Updates
            id={id}
            referenceKey={referenceKey}
            dataResource={updatesDataResource}
          />
        </div>
      </div>
      {info?.numberOfTours > 0 ? (
        <div style={{ display: "flex", alignItems: "center" }}>
          <StatusButton
            currentStatus={info?.status}
            statuses={
              resource === enums.Resource.INVOICES
                ? enums.InvoiceStatus
                : enums.BillStatus
            }
            resource={resource}
            updateInfo={updateInfo}
          />
        </div>
      ) : null}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Tours"
            addIndexColumn
          />
        </GridItem>
      </GridContainer>
      <History id={id} resource={resource} status={info?.status} />
    </div>
  );
}

FinOpsEdit.propTypes = {
  resource: PropTypes.string,
  request: PropTypes.func,
  updatesDataResource: PropTypes.object,
  referenceKey: PropTypes.string,
  columns: PropTypes.array,
  infoColumns: PropTypes.array,
};
