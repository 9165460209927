import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import translations from "constants/translations";
import enums from "enums/index";
import React, { useMemo } from "react";
import { getI18n } from "react-i18next";
import { Link } from "react-router-dom";
import { MonthlyOrderContext } from "./MonthlyOrders.context";
import useMonthlyOrder from "./MonthlyOrders.hook";
import styles from "./MonthlyOrders.styles";
import { MonthlyOrderModal } from "./components/MonthlyOrderModal/MonthlyOrderModal";
import api from "services/api";
import Tooltip from "@material-ui/core/Tooltip";
import FilterSelector from "views/FinOps/FinOpsList/components/FilterSelector/FilterSelector";
import MonthlyStatusButton from "views/Plans/MonthlyOrderDetails/components/MonthlyStatusButton/MonthlyStatusButton";
import MonthlyOrderDetailsModal from "./components/MonthlyOrderDetailsModal/MonthlyOrderDetailsModal";
import { extractTimeFromISO } from "utils/index";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);
export default function MonthlyOrders({ resource }) {
  const classes = useStyles();
  const {
    statusFilterOptions,
    filtersState,
    searchQuery,
    isLoading,
    isCreateOrderModalOpen,
    monthlyOrders,
    organizations,
    organizationId,
    isEditingOrder,
    currentOrder,
    setCurrentOrder,
    setIsEditingOrder,
    updateOrder,
    setFiltersState,
    getLatestOrders,
    onFilterChange,
    onSearchChange,
    handleCreateMonthlyOrder,
    handleEditMonthlyOrder,
    handleCopyMonthlyOrder,
    onCreateOrderModalClose,
  } = useMonthlyOrder(resource);
  const filterInputs = useMemo(
    () =>
      resource === enums.Resource.MONTHLY_FULFILLMENT
        ? [
            {
              name: "organization",
              label: "Organization",
              type: "autocomplete",
              options: {
                data: organizations,
                getOptionLabel: (option) => option?.name,
              },
            },
            {
              name: "serviceProvider",
              label: "Service Provider",
              type: "autocomplete",
              options: {
                getData: api.serviceProviders.getAll,
                getOptionLabel: (option) => option?.name,
                addAllChoice: true,
              },
            },
            {
              name: "fulfillment",
              label: "Fulfillment",
              type: "select",
              options: {
                data: Object.keys(
                  translations[getI18n().language].fulfillmentStatus
                ).map((key) => ({
                  value: key,
                  name: translations[getI18n().language].fulfillmentStatus[key],
                })),
                addAllChoice: true,
              },
            },
          ]
        : null,
    [organizations]
  );
  const columns = React.useMemo(
    () => [
      {
        title: "Truck Type",
        dataIndex: ["monthly", "truckType", "details", "name"],
      },
      {
        title: "Pickup Location",
        dataIndex: ["monthly", "pickupLocation", "name"],
      },
      {
        title: "Monthly Distance Quota",
        dataIndex: ["monthly", "monthlyDistanceLimit"],
      },
      {
        title: "Daily Duration Limit",
        dataIndex: ["monthly", "dailyDurationLimit"],
      },
      {
        title: "Off-Days",
        dataIndex: ["monthly", "offDays"],
        getData: (data) => {
          if (data) {
            return data.reduce((pre, curr) => {
              curr = translations[getI18n().language].weekDay[curr];
              return pre ? pre + " - " + curr : curr;
            }, "");
          } else return "";
        },
      },
      {
        title: "Start Date",
        dataIndex: ["startDate"],
      },
      {
        title: "End Date",
        dataIndex: ["endDate"],
      },
      {
        title: "Start Time",
        dataIndex: ["monthly", "pickupTime"],
        getData: (value) => {
          const time = extractTimeFromISO(value);
          return time;
        },
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div style={{ display: "flex", width: "fit-content" }}>
              <CheckPermission
                resource={enums.Resource.PLANS}
                action={enums.Action.READ}
              >
                {resource === enums.Resource.MONTHLY_FULFILLMENT && (
                  <Link
                    to={`/admin/monthly/${organizationId}/${record?.realId}`}
                  >
                    <Tooltip
                      title={"View Order"}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                      arrow
                    >
                      <Button
                        className={classes.actionButton}
                        justIcon
                        view
                        color="white"
                        textColor="primary"
                      />
                    </Tooltip>
                  </Link>
                )}
                {resource !== enums.Resource.MONTHLY_FULFILLMENT && (
                  <>
                    {
                      <MonthlyOrderDetailsModal
                        justIcon
                        className={classes.actionButton}
                        view
                        color="white"
                        order={record}
                      />
                    }
                    {[
                      enums.OrderStatus.ACTIVE,
                      enums.OrderStatus.PLANNED,
                    ].includes(record.status) && (
                      <Tooltip
                        title={"Edit Order"}
                        placement="bottom"
                        classes={{ tooltip: classes.tooltip }}
                        arrow
                      >
                        <Button
                          justIcon
                          edit
                          color="white"
                          textColor="primary"
                          className={classes.actionButton}
                          onClick={() => {
                            handleEditMonthlyOrder(record);
                          }}
                        />
                      </Tooltip>
                    )}
                    <Tooltip
                      title={"Duplicate Order"}
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                      arrow
                    >
                      <Button
                        justIcon
                        copy
                        color="white"
                        textColor="primary"
                        className={classes.actionButton}
                        onClick={() => {
                          handleCopyMonthlyOrder(record);
                        }}
                      />
                    </Tooltip>
                    <MonthlyStatusButton
                      order={record}
                      targetStatus={enums.OrderStatus.ACTIVE}
                      updateOrder={(data) => {
                        updateOrder(record.realId, data);
                      }}
                      justIcon
                      next
                    />
                    <MonthlyStatusButton
                      order={record}
                      targetStatus={enums.OrderStatus.CANCELLED}
                      updateOrder={(data) => {
                        updateOrder(record.realId, data);
                      }}
                      justIcon
                      cancelButton
                    />
                    <MonthlyStatusButton
                      order={record}
                      targetStatus={enums.OrderStatus.TERMINATED}
                      updateOrder={(data) => {
                        updateOrder(record.realId, data);
                      }}
                      justIcon
                      deleteButton
                    />
                  </>
                )}
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [monthlyOrders]
  );

  return (
    <MonthlyOrderContext.Provider
      value={{
        getLatestOrders: getLatestOrders,
        isGetOrdersLoading: isLoading,
        monthlyOrders: monthlyOrders,
      }}
    >
      <div className={classes.root}>
        <div className={classes.section}>
          {isCreateOrderModalOpen && (
            <MonthlyOrderModal
              isOpen={isCreateOrderModalOpen}
              onClose={onCreateOrderModalClose}
              isEditingOrder={isEditingOrder}
              currentOrder={currentOrder}
              setIsEditingOrder={setIsEditingOrder}
              setCurrentOrder={setCurrentOrder}
            />
          )}
          <React.Fragment>
            <div className={classes.headerContainer}>
              <h3 className={classes.header}>Monthly Orders</h3>
              {resource !== enums.Resource.MONTHLY_FULFILLMENT && (
                <div className={classes.buttonsContainer}>
                  <CheckPermission
                    resource={enums.Resource.PLANS}
                    action={enums.Action.CREATE}
                  >
                    <Button
                      onClick={handleCreateMonthlyOrder}
                      className={classes.addContactButton}
                      color="success"
                    >
                      <AddIcon className={classes.addIcon} />
                      Add Monthly Order
                    </Button>
                  </CheckPermission>
                </div>
              )}
            </div>
            <FilterSelector
              options={statusFilterOptions}
              onSelect={(values) => {
                setFiltersState((prev) => ({
                  ...prev,
                  status: values === "ALL" ? undefined : values,
                }));
              }}
              horizontal
              tabs
            />
            <div className={classes.tableContainer}>
              <CustomTable
                columns={columns}
                data={monthlyOrders}
                filterProps={
                  resource === enums.Resource.MONTHLY_FULFILLMENT && {
                    value: filtersState,
                    onChange: onFilterChange,
                    inputs: filterInputs,
                  }
                }
                searchProps={
                  resource === enums.Resource.MONTHLY_FULFILLMENT && {
                    onChange: onSearchChange,
                    value: searchQuery,
                    placeholder: "Search",
                  }
                }
                loading={isLoading}
                handleSubmit={() => {}}
                addIndexColumn
              />
            </div>
          </React.Fragment>
        </div>
      </div>
    </MonthlyOrderContext.Provider>
  );
}
