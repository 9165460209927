import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import CustomTable from "components/CustomTable/CustomTable";
import api from "services/api";
import useAssignServiceProviderRentalModal from "./AssignServiceProviderRentalModal.hook";
import styles from "./AssignServiceProviderRentalModal.styles";
import React from "react";
import { RentalDealStatusColors } from "constants/colors";
import translations from "constants/translations/index";
import i18n from "i18n";
import { getI18n } from "react-i18next";
import { getDateString } from "utils/index";
import CancelServiceProviderRental from "views/ServiceProviderRentals/pages/ServiceProviderRentalView/CancelServiceProviderRental/CancelServiceProviderRental";
//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AssignServiceProviderRentalModal(props) {
  const { isOpen, onClose, currentTour } = props;
  const {
    rentals,
    isLoading,
    currentPage,
    itemsPerPage,
    filtersState,
    searchQuery,
    highlightedText,
    tourToCancel,
    isCancelModalOpen,
    onCloseModal,
    handleSubmit,
    handleChange,
    onSelect,
    onFilterChange,
    onSearchChange,
    toggleCancelModal,
  } = useAssignServiceProviderRentalModal({
    currentTour,
    isOpen,
    onClose,
  });
  const classes = useStyles();
  const columns = [
    {
      title: "Service Provider",
      dataIndex: ["serviceProvider", "name"],
    },
    {
      title: "Truck Type",
      dataIndex: ["truckType", "details", "name"],
    },
    {
      title: "Start Date",
      dataIndex: ["startDate"],
      getData: (date) => {
        return date ? getDateString(date) : "";
      },
    },
    {
      title: "End Date",
      dataIndex: ["endDate"],
      getData: (date) => {
        return date ? getDateString(date) : "";
      },
    },
    {
      title: "Tour",
      dataIndex: ["tours"],
      getData: (tours) => {
        return tours?.length
          ? `${tours[0].id}-${tours[0]?.order?.deal?.organization?.name}`
          : "Unassigned";
      },
    },
    {
      title: "Status",
      dataIndex: ["status"],
      getData: (status) => {
        return (
          <div
            style={{
              color: RentalDealStatusColors[status],
              fontWeight: 600,
            }}
          >
            {translations[i18n.language].rentalDealStatus[status]}
          </div>
        );
      },
    },
    {
      title: "",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", width: "fit-content" }}>
            <Tooltip
              title={"Select Service Provider Rental"}
              placement="bottom"
              classes={{ tooltip: classes.tooltip }}
              arrow
            >
              <Button
                className={classes.actionButton}
                onClick={() => {
                  if (record.tours.length) {
                    toggleCancelModal(record.tours[0]);
                    return;
                  }
                  onSelect(record);
                }}
                justIcon
                create
                color="primary"
                textColor="white"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const filterInputs = [
    {
      name: "serviceProvider",
      label: "Service Provider",
      type: "autocomplete",
      options: {
        getData: api.serviceProviders.getAll,
        getOptionLabel: (option) => option?.name,
        addAllChoice: true,
      },
    },
    {
      name: "truckType",
      label: "Truck Type",
      type: "autocomplete",
      options: {
        getData: api.truckTypes.getAll,
        getOptionLabel: (option) => option?.details?.name,
        addAllChoice: true,
      },
    },
  ];
  return (
    isOpen && (
      <div>
        <CustomModal
          open
          onClose={() => {
            onCloseModal();
          }}
          modalHeaderClassName={classes.modalHeader}
          modalBodyClassName={classes.modalBody}
        >
          <Card className={classes.cardRoot}>
            <CardBody>
              <div className={classes.formHeader}>
                <EditIcon className={classes.icon} />
                {`Select Service Provider Rental`}
              </div>
              <div className={classes.tableContainer}>
                <CustomTable
                  columns={columns}
                  data={rentals.rows}
                  pagination={{
                    total: rentals.count,
                    current: currentPage,
                    pageSize: itemsPerPage,
                    position: ["topRight"],
                    pageSizeOptions: [10, 20, 50],
                  }}
                  filterProps={{
                    value: filtersState,
                    onChange: onFilterChange,
                    inputs: filterInputs,
                  }}
                  searchProps={{
                    onChange: onSearchChange,
                    value: searchQuery,
                    placeholder: "Search",
                  }}
                  loading={isLoading}
                  highlightedText={highlightedText}
                  onChange={handleChange}
                  handleSubmit={handleSubmit}
                  addIndexColumn
                />
              </div>
            </CardBody>
          </Card>
        </CustomModal>
        <CancelServiceProviderRental
          currentTour={currentTour}
          tourToCancel={tourToCancel}
          isCancelModalOpen={isCancelModalOpen}
          toggleCancelModal={toggleCancelModal}
          onClose={onClose}
        />
      </div>
    )
  );
}
