import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import FormField from "components/Forms/FormField/FormField";
import React from "react";
import useInvoiceModal from "./InvoiceModal.hook";
import styles from "./InvoiceModal.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function InvoiceModal(props) {
  const { form, submitHandler } = useInvoiceModal(props);

  const classes = useStyles();
  return (
    <Card style={{ margin: 0 }}>
      <CardBody>
        <div className={classes.formHeader}>
          <React.Fragment>
            <AddIcon className={classes.icon} />
            Add Invoice
          </React.Fragment>
        </div>
        <form
          style={{ padding: 20, paddingBottom: 0 }}
          onSubmit={submitHandler}
          autoComplete="off"
        >
          <FormField
            md={12}
            form={form}
            name="name"
            type="text"
            label="Invoice Name"
            options={{
              blurOnSelect: false,
            }}
          />
          <FormField
            md={12}
            form={form}
            name="type"
            type="select"
            label="Invoice Type"
            options={{
              enum: "invoiceType",
            }}
          />
          <FormField
            md={12}
            form={form}
            name="expectedInvoiceDate"
            type="date"
            label="Expected Invoice Date"
            options={{
              blurOnSelect: false,
            }}
          />

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="primary"
              type="submit"
              // loading={isLoading}
              style={{ margin: "20px 0" }}
              create
              loadFromRight
            >
              Add Invoice
            </Button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}
