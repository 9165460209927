import enums from "enums/index";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import TaskTableValidation from "./TaskTable.validation";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { TourOperationsContext } from "views/TourOperations/TourOperations.context";
import { convertTimeToISO } from "utils/index";
import { getCurrentTaskDate } from "./TasksTable.utils";

// ------------------------------------------------------------------------------------------

export default function useTasksTable({ sendTasksRequest }) {
  const {
    resource,
    currentTour,
    setCurrentTour,
    getOrganizations,
    getTours,
  } = useContext(TourOperationsContext);
  const resolver = useYupValidationResolver(TaskTableValidation());
  const formRef = useRef(null);
  const dataResource = useMemo(() => api.tours, []);

  const [currentTask, setCurrentTask] = useState(null);
  const [tourLocationsSendRequest, isTourLocationsLoading] = useHttpClient(
    true
  );
  const [sendGetCitiesRequest, isCitiesLoading] = useHttpClient(true);
  const [sendGetDistrictsRequest, isDistrictsLoading] = useHttpClient(true);
  const [tourQuotation, setTourQuotation] = useState(null);
  const [tourPickupLocation, setTourPickupLocation] = useState(null);
  const [allCities, setAllCities] = useState([]);
  const [allDistricts, setAllDistricts] = useState([]);
  const defaultTask = useMemo(
    () => ({
      type: enums.TaskType.DROP_OFF,
      city: null,
      district: null,
      location: null,
      status: enums.TaskStatus.UPCOMING,
    }),
    []
  );

  // is any service has an invoiced invoice, or a billed bill
  const isServiceProcessed = useMemo(
    () =>
      currentTour?.services.some(
        (service) =>
          service.invoice?.status === enums.InvoiceStatus.INVOICED ||
          service.bill?.status === enums.BillStatus.BILLED
      ),
    [currentTour]
  );

  const tourDisabled = useMemo(
    () =>
      currentTour?.status === enums.TourStatus.CANCELLED ||
      currentTour?.status === enums.TourStatus.COMPLETED,
    [currentTour]
  );

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: { tasks: [] },
    watch,
  });

  const { handleSubmit, setValue, getValues, reset, control, watch } = form;
  const { fields: tasks, insert, append, remove } = useFieldArray({
    control,
    name: "tasks",
  });

  const firstTaskType = watch("tasks.0.type");
  useEffect(() => {
    const currentTasks = currentTour?.tasks || [];
    reset({ tasks: [...currentTasks] });
  }, [currentTour]);
  const tourPickupLocationId = watch("tasks.0.location")?.id;

  async function getTourLocations() {
    if (currentTour) {
      try {
        const tourQuotation = (
          await tourLocationsSendRequest(
            api.tours.getTourQuotationById(currentTour.id)
          )
        )[0];
        setTourQuotation(tourQuotation);
      } catch (err) {
        console.log("error:", err);
      }
    }
  }

  useEffect(() => {
    getTourLocations();
  }, [currentTour]);
  const quotationLocations = useMemo(
    () =>
      tourQuotation?.tourType === enums.TourType.PER_TRIP
        ? tourQuotation?.truckTypes[0]?.pickupLocations
        : tourQuotation?.truckTypes[0]?.distanceLimits[0]?.durationLimits[0]
            ?.locations,
    [tourQuotation]
  );
  const quotationDistricts = useMemo(
    () =>
      tourQuotation?.tourType === enums.TourType.PER_TRIP
        ? tourQuotation?.truckTypes[0]?.pickupDistricts
        : tourQuotation?.truckTypes[0]?.distanceLimits[0]?.durationLimits[0]
            ?.districts,
    [tourQuotation]
  );
  const quotationCities = useMemo(
    () =>
      tourQuotation?.tourType === enums.TourType.PER_TRIP
        ? tourQuotation?.truckTypes[0]?.pickupCities
        : tourQuotation?.truckTypes[0]?.distanceLimits[0]?.durationLimits[0]
            ?.cities,
    [tourQuotation]
  );

  const tourType = useMemo(() => tourQuotation?.tourType, [tourQuotation]);

  useEffect(() => {
    (async () => {
      try {
        const citiesPromise = sendGetCitiesRequest(api.cities.getAll());
        const districtsPromise = sendGetDistrictsRequest(
          api.districts.getAll()
        );
        const [citiesResponse, districtResponse] = await Promise.all([
          citiesPromise,
          districtsPromise,
        ]);
        setAllCities(citiesResponse.data);
        setAllDistricts(districtResponse.data);
      } catch (err) {}
    })();
  }, []);

  const onAdd = useCallback(
    (index) => {
      if (index === undefined) {
        append({
          ...defaultTask,
          type: enums.TaskType.DROP_OFF,
          driverId: currentTour.driverId,
          truckId: currentTour.truckId,
          serviceProviderId: currentTour.serviceProviderId,
          courierId: currentTour.courierId,
        });
        return;
      }
      insert(index + 1, {
        ...defaultTask,
        type: enums.TaskType.DROP_OFF,
        driverId: currentTour.driverId,
        truckId: currentTour.truckId,
        serviceProviderId: currentTour.serviceProviderId,
        courierId: currentTour.courierId,
      });
    },
    [defaultTask, insert, currentTour]
  );

  const onRemove = useCallback(
    (index) => {
      remove(index);
    },
    [remove]
  );

  const submitHandler = (requestData) => async () => {
    try {
      let referenceDate = currentTour.pickupTime;
      const values = requestData.tasks.map((task, i, array) => {
        const { startDate, endDate } = getCurrentTaskDate(
          referenceDate,
          i,
          task
        );
        referenceDate = endDate || startDate || referenceDate;
        let newTask = { ...task };
        newTask.locationId = newTask.location.id;
        newTask.tourId = currentTour.id;
        newTask.startTime = newTask.startTime
          ? convertTimeToISO(startDate, newTask.startTime)
          : null;
        newTask.endTime = newTask.endTime
          ? convertTimeToISO(endDate, newTask.endTime)
          : null;
        delete newTask.location;
        return newTask;
      });
      const newTour = { id: currentTour.id, tasks: values };
      const tourUpdate = await sendTasksRequest(
        dataResource.updateTourTasks(currentTour.id, newTour)
      );
      setCurrentTour({
        ...currentTour,
        tasks: tourUpdate.tasks,
        services: tourUpdate.services,
      });
      toast.success(`Tour has been updated successfully`);
    } catch (err) {
      if (err?.response?.data?.error === "Conflict") {
        await getOrganizations();
        await getTours();
      }
    }
  };
  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let requestData = { ...getValues() };
    handleSubmit(submitHandler(requestData), onError)();
  };

  const handleChange = useCallback(
    (value, key, index) => {
      setValue(`tasks.${index}.${key}`, value);
    },
    [setValue]
  );

  const updateTasks = useCallback(() => {
    if (!currentTour) return;
    let requestData = { ...getValues() };
    let referenceDate = currentTour.pickupTime;
    const values = requestData?.tasks?.map((task, i, array) => {
      const { startDate, endDate } = getCurrentTaskDate(referenceDate, i, task);
      referenceDate = endDate || startDate || referenceDate;
      const newTask = {
        ...task,
        tourId: currentTour.id,
        startTime: task.startTime
          ? convertTimeToISO(startDate, task.startTime)
          : null,
        endTime: task.endTime ? convertTimeToISO(endDate, task.endTime) : null,
      };
      return newTask;
    });
    setCurrentTour({ ...currentTour, tasks: values });
  }, [currentTour]);

  const tasksWithTime = useMemo(() => {
    if (!currentTour) return;
    let referenceDate = currentTour?.pickupTime;
    const values = watch("tasks")?.map((task, i, array) => {
      const { startDate, endDate } = getCurrentTaskDate(referenceDate, i, task);
      referenceDate = endDate || startDate || referenceDate;
      const newTask = {
        startTime: task.startTime
          ? convertTimeToISO(startDate, task.startTime)
          : null,
        endTime: task.endTime ? convertTimeToISO(endDate, task.endTime) : null,
      };
      return newTask;
    });
    return values;
  }, [watch("tasks")]);

  return {
    resource,
    form,
    formRef,
    onSubmit,
    onAdd,
    onRemove,
    handleChange,
    defaultTask,
    tasks,
    quotationLocations,
    quotationCities,
    quotationDistricts,
    tourType,
    tourPickupLocationId,
    tourPickupLocation,
    currentTask,
    tourDisabled,
    isServiceProcessed,
    allCities,
    allDistricts,
    tasksWithTime,
    setTourPickupLocation,
    getTourLocations,
    setCurrentTask,
    updateTasks,
  };
}
